import React, { memo, forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { ConentBlock } from '@politechdev/blocks-design-system'
import styles from './StaticList.module.scss'

const cx = classNames.bind(styles)

const StaticList = forwardRef(
  ({ itemData, render, loading, emptyMessage }, ref) => {
    const display =
      itemData && itemData.length ? (
        itemData.map((item, index) => render(item, index))
      ) : (
        <ConentBlock className={styles['static-list__empty']}>
          <p>{emptyMessage}</p>
        </ConentBlock>
      )

    return (
      <div className={styles['static-list__wrapper']}>
        <div
          className={cx('static-list__overlay', {
            'static-list__overlay--active': loading,
          })}
        />
        <ul ref={ref} className={styles['static-list__ul']}>
          {display}
        </ul>
      </div>
    )
  }
)

StaticList.propTypes = {
  itemData: PropTypes.array,
  render: PropTypes.func,
  loading: PropTypes.bool,
  emptyMessage: PropTypes.string,
}

StaticList.defaultProps = {
  itemData: [],
  render: () => {},
  loading: false,
  emptyMessage: 'No items',
}

export default memo(StaticList)
